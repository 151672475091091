<template>
  <div class="images-list">
    <div class="top">
      <div class="top-title-wrap">
        <p class="top-title">{{ $t('images.make') }}</p>
        <el-tooltip popper-class="stepInfo" :effect="$isDark() ? 'dark' : 'light'" placement="right-end">
          <template #content>
            <div style="line-height: 24px;font-size: 14px;">
              <div>{{ $t('images.step.title') }}</div>
              <div>{{ $t('images.step.step1') }}</div>
              <div>{{ $t('images.step.step2') }}</div>
              <div>{{ $t('images.step.step3') }}</div>
              <div>{{ $t('images.step.step4') }}</div>
              <div>{{ $t('images.step.final') }}</div>
            </div>
          </template>
          <svg-icon name="zhushi"></svg-icon>
        </el-tooltip>
      </div>
      <p>{{ $t('images.desc1') }}</p>
      <p>{{ $t('images.desc2') }}</p>
    </div>
    <template v-if="!initloading">
      <div class="nodata" v-if="!list.length">
        <no-data :data="$t('images.nodata')"></no-data>
        <el-button class="apply-btn" @click="addRow" type="primary">{{ $t('images.add') }}</el-button>
      </div>
      <div class="table-wrap" v-else>
        <div class="table-header" style="margin-bottom: 16px;display: flex; justify-content: space-between; align-items: center">
          <el-button
            type="primary"
            @click.prevent="addRow"
          >
            新建
          </el-button>
          <el-tooltip class="item" :effect="$isDark() ? 'dark' : 'light'" content="发布记录" placement="top">
            <el-button style="width: 32px;" @click="toHistory">
              <el-icon style="font-size: 16px;"><Clock /></el-icon>
            </el-button>
          </el-tooltip>
        </div>
        <div class="list-wrap">
          <el-table
            v-loading="loading"
            ref="multipleTableRef"
            :data="list"
            style="width: 100%"
            tooltip-effect="light"
            class="images-table"
          >
            <!-- <el-table-column type="selection" width="55" /> -->
            <el-table-column property="custom_name" :tooltip-options="{ effect: 'light', showArrow: false }" show-overflow-tooltip label="镜像名称" min-width="120" />
            <el-table-column property="desc" show-overflow-tooltip label="描述" min-width="160">
            </el-table-column>
            <el-table-column property="create_time" show-overflow-tooltip label="创建时间" min-width="120">
            </el-table-column>
            <el-table-column property="status" show-overflow-tooltip label="状态" min-width="60">
              <template #default="scope"><span class="dot" :style="{background: formatDot(scope.row)}"></span>{{ formatStatus(scope.row) }}</template>
            </el-table-column>
            <el-table-column label="操作" width="260" fixed="right">
              <template #default="scope">
                <el-button class="table-operation-btn" v-if="scope.row.status !== 'use' && scope.row.status !== 'invalid'" @click="handleEdit(scope.row)" :disabled="scope.row.status === 'publishing_d'|| scope.row.status === 'publishing' || scope.row.status === 'use'" type="text">制作</el-button>
                <el-button class="table-operation-btn" v-else @click="toDetail(scope.row)" type="text">查看</el-button>
                <el-button class="table-operation-btn" type="text" :disabled="scope.row.status!== 'saved' && scope.row.status!== 'publish_err' || !scope.row.operation_status || ((scope.row.operation_status == 1 || scope.row.operation_status == 2) && scope.row.status === 'saved')" @click="publishImageFn(scope.row)">发布</el-button>
                <el-button class="table-operation-btn" :style="{width: isHasUsed ? '56px' : 'auto'}" type="text" v-if="scope.row.status !== 'use'" :disabled="scope.row.status!== 'published'" @click="useImageFn(scope.row)">应用</el-button>
                <el-button class="table-operation-btn" type="text" v-else @click="cancelApply(scope.row)">取消应用</el-button>
                <el-dropdown class="dropdown-wrapper" style="margin-left: 16px;" trigger="hover">
                  <el-button type="text" class="more-btn">
                    更多
                    <el-icon class="arrow-icon"><ArrowDown /></el-icon>
                  </el-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item command="edit" :disabled="scope.row.status === 'publishing_d'|| scope.row.status === 'publishing' || scope.row.status === 'use'">
                        <el-button type="text" @click="toEdit(scope.row)">编辑</el-button>
                      </el-dropdown-item>
                      <el-dropdown-item command="delete" :disabled="scope.row.operation_status === 2 || scope.row.status === 'publishing_d'|| scope.row.status === 'publishing' || scope.row.status === 'use'">
                        <el-button type="text" :disabled="scope.row.operation_status === 2 || scope.row.status === 'publishing_d'|| scope.row.status === 'publishing' || scope.row.status === 'use'" @click="toDelete(scope.row)">删除</el-button>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination-wrap" v-if="list.length">
          <Pagination
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :totalSize="total"
            :current_page="page"
            :pageSizes="[10, 50]"
          />
        </div>
      </div>
    </template>
    <el-dialog
      v-model="dialogVisible"
      :title="imageId ? '编辑镜像' : '新建镜像'"
      width="650px"
      :close-on-click-modal="false"
    >
      <el-form
        ref="formRef"
        :model="formModel"
        :rules="rules"
        label-width="80px"
        :status-icon="false"
        class="imagesListForm"
      >
        <p style="font-size:14px;color:var(--color-text-2)">基于当前已有镜像，创建一个新的镜像</p>
        <configBox
          :key="configBoxKey"
          :configData="useConfig"
          @update:configData="updateconfigData"
          @delete:configData="deleteconfigData"
          @update:machineConfigData="updateconfigMachineData"
          @updateSoftWare="updateSoftWare"
          type="file"
          :taskType="formData.config.os"
          :curFileType="''"
          :disabled="!!imageId"
        ></configBox>
        <el-form-item label="镜像名称" prop="custom_name" required>
          <el-input v-model="formModel.custom_name" :disabled="!!imageId" show-word-limit maxlength="128" placeholder="支持英文大小写、数字和“-”"></el-input>
          <span v-if="!imageId" style="color: var(--color-text-3);font-size: 12px;line-height: 24px;">保存后不允许修改，请谨慎填写</span>
        </el-form-item>
        <el-form-item label="镜像描述" prop="desc">
          <el-input v-model="formModel.desc" type="textarea" :disabled="isExpired" show-word-limit maxlength="200" placeholder="请输入描述"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancelDialog">{{ isExpired ? '确定' : '取消' }}</el-button>
          <el-button v-if="!isExpired" type="primary" :loading="saveLoading" @click="addImages">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="deleteVisiable"
      title="删除镜像"
      width="450px"
    >
      <p>删除镜像后，镜像不可找回，请确认是否删除</p>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="deleteVisiable = false">取消</el-button>
          <el-button type="primary" @click="deleteAction">确认删除</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="cancelApplyVisiable"
      title="取消应用镜像"
      width="450px"
    >
      <p style="font-size: 14px;line-height: 22px;">取消应用镜像后，镜像将不再出现在提交配置的操作系统，请确定是否取消</p>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancelApplyVisiable = false">取消</el-button>
          <el-button type="primary" @click="cancelApplyAction">确认取消</el-button>
        </span>
      </template>
    </el-dialog>
    <history-logs v-if="drawer" v-model:visible="drawer"/>
    <images-info :isEdit="false" v-if="imagesInfoVisible" v-model:visible="imagesInfoVisible" :initialData="imagesDetail"/>
  </div>
</template>

<script setup>
import { ref, watch, computed, onUnmounted } from 'vue'
import Pagination from '@/components/common/Pagination'
import configBox from './config'
import ImagesInfo from './ImagesInfo.vue'
import { useRouter, useRoute } from 'vue-router'
import { postaddImages, getImages, deleteImage, publishImage, useImage, unuseImage, getImageById } from 'services/custom-images'
import { message } from '@/hooks/useUI'
import { Clock, ArrowDown } from '@element-plus/icons-vue'
import HistoryLogs from './history.vue'
import emitter from 'app/emitter'
import store from '@/store'

const router = useRouter()
const route = useRoute()
const list = ref([])
const dialogVisible = ref(false)
const formRef = ref(null)
const configBoxKey = ref(0)
const imageId = ref('')
const loading = ref(true)
const saveLoading = ref(false)
const initloading = ref(true)
const total = ref(1)
const size = ref(10)
const page = ref(1)
const drawer = ref(false)
const deleteVisiable = ref(false)
const cancelApplyVisiable = ref(false)
const imagesInfoVisible = ref(false)
const imagesDetail = ref()
const isExpired = ref(false)
// const timer = ref()
watch(dialogVisible, v => {
  if (v) {
    configBoxKey.value += 1
  } else {
    formRef.value.resetFields()
  }
})
const addRow = () => {
  imageId.value = ''
  formModel.value.custom_name = ''
  formModel.value.desc = ''
  baseConfig.Windows.machine = ''
  dialogVisible.value = true
}
const baseConfig = {
  Windows: {
  }
}
const handleEdit = async (row) => {
  const data = await getImageById(row.id)
  emitter.emit('imageInfo', data)
  imageId.value = row.id
  router.push({
    name: route.name,
    params: {
      ...route.params, // 保留其他参数
      id: imageId.value // 替换 id 参数
    }
  })
}
function toHistory() {
  drawer.value = true
}
function toDetail(row) {
  imagesDetail.value = row
  imagesInfoVisible.value = true
}
const toEdit = row => {
  // if (row.status === 'publishing_d' || row.status === 'publishing') return
  formModel.value.custom_name = row.custom_name
  formModel.value.desc = row.desc
  baseConfig.Windows.machine = row.machine.replace(/_[^_]*_[^_]*$/, '')
  imageId.value = row.id
  dialogVisible.value = true
  isExpired.value = row.status === 'invalid'
}
const rules = ref({
  custom_name: [
    { required: true, message: '请输入镜像名称！', trigger: 'blur' },
    {
      validator: (rule, value, callback) => {
        if (!/^[A-Za-z0-9][A-Za-z0-9_]*$/.test(value)) {
          callback(new Error('请输入只包含英文大小写，数字和”_“的字符，且不能以”_“开头'))
        } else if (value.length > 128) {
          callback(new Error('输入字符不能超过128个'))
        } else {
          callback()
        }
      },
      trigger: 'blur'
    }
  ]
})
const formData = ref({
  conf_name: '',
  conf_desc: '',
  priority: 10,
  file_max_size: '',
  submit_type: [1],
  file_types: [],
  file_path: '',
  config: {
    os: 'Windows'
  }
})
const formModel = ref({
  custom_name: ''
})
const useConfig = computed({
  get: () => {
    return baseConfig[formData.value.config.os]
  },
  set: val => {
    baseConfig[formData.value.config.os] = val
  }
})
const formatStatus = (row) => {
  const map = {
    saved: '待发布',
    published: '待应用',
    publishing: '发布中',
    publishing_d: '发布中',
    use: '已应用',
    publish_err: '发布失败',
    invalid: '已失效'
  }
  if ((row.operation_status === 1 || row.operation_status === 2) && row.status === 'saved') return '制作中'
  if ((!row.operation_status) && row.status === 'saved') return '未初始化'
  return map[row.status]
}
const formatDot = (row) => {
  const map = {
    saved: '#1890FF',
    published: '#AEB2B8',
    publishing: '#F58A02',
    publishing_d: '#F58A02',
    use: '#00AB7A',
    publish_err: '#F5222D',
    invalid: '#D9D9D9'
  }
  if ((row.operation_status === 1 || row.operation_status === 2) && row.status === 'saved') return '#DFBC00'
  if ((!row.operation_status) && row.status === 'saved') return '#DFBC00'
  return map[row.status] || '#808080'
}
const cancelDialog = () => {
  formModel.value = {
    custom_name: '',
    desc: ''
  }
  dialogVisible.value = false
}
const useImageFn = row => {
  if (row.status !== 'published') return
  useImage({
    image_id: row.id
  }).then(res => {
    const { code, message: errMessage } = res
    if (code) {
      message(errMessage, 'error')
    } else {
      message('应用成功，请在任务提交配置页面，选择此操作系统使用', 'success')
      list.value.find(v => v.id === row.id).status = 'use'
    }
  })
}
const cancelApply = row => {
  imageId.value = row.id
  cancelApplyVisiable.value = true
}
const cancelApplyAction = () => {
  cancelApplyVisiable.value = false
  unuseImage({
    image_id: imageId.value
  }).then(res => {
    const { code, message: errMessage } = res
    if (code) {
      message(errMessage, 'error')
    } else {
      message('取消应用成功')
      list.value.find(v => v.id === imageId.value).status = 'published'
    }
  })
}
const publishImageFn = row => {
  if (row.status !== 'saved' && row.status !== 'publish_err') return
  publishImage({
    image_id: row.id
  }).then(res => {
    const { code, message: errMessage } = res
    if (code) {
      message(errMessage, 'error')
    } else {
      message('正在发布，请稍后', 'success')
      // list.value.find(v => v.id === row.id).status = 'publishing_d'
      getData(false)
      // timer.value = setInterval(() => {
      //   getData(false)
      // }, 10000)
    }
  })
}
onUnmounted(() => {
  // clearInterval(timer.value)
  if (refreshTimer) {
    clearInterval(refreshTimer)
    refreshTimer = null
  }
})
const toDelete = row => {
  if (row.operation_status === 2 || row.status === 'publishing_d' || row.status === 'publishing' || row.status === 'use') return
  imageId.value = row.id
  deleteVisiable.value = true
}
const deleteAction = () => {
  deleteVisiable.value = false
  deleteImage({
    image_id: imageId.value
  }).then(res => {
    const { code, message: errMessage } = res
    if (code) {
      message(errMessage, 'error')
    } else {
      message('删除成功')
      getData()
    }
  })
}
const addImages = () => {
  formRef.value.validate(valid => {
    if (valid) {
      const data = {
        custom_name: formModel.value.custom_name,
        desc: formModel.value.desc,
        machine: baseConfig.Windows.machine,
        software: formModel.value.software,
        os_alias: document.querySelector('.os-alias input').value
      }
      if (imageId.value) data.image_id = imageId.value
      if (!formModel.value.desc) delete data.desc
      saveLoading.value = true
      postaddImages(data).then(res => {
        saveLoading.value = false
        const { code, message: errMessage } = res
        if (code) {
          if (code === 60021 || code === 60022) {
            store.commit('SHOWFEEDBACK', errMessage)
            return
          }
          message(errMessage, 'error')
        } else {
          message('操作成功')
          getData()
          dialogVisible.value = false
        }
      })
    }
  })
}
const handleSizeChange = (_size) => {
  size.value = _size
  handleCurrentChange(1)
}
const handleCurrentChange = (_page) => {
  page.value = _page
  getData()
}
const getData = (needLoading = true) => {
  if (needLoading) loading.value = true
  getImages({
    page: page.value,
    size: size.value
  }).then(res => {
    loading.value = false
    initloading.value = false
    list.value = res.content
    total.value = res.total
    checkForPublishingStatus()
  })
}
let refreshTimer = null
const checkForPublishingStatus = () => {
  const isPublishing = list.value.some(item => item.status === 'publishing' || item.status === 'publishing_d')
  const isMaking = list.value.some(item => (item.operation_status === 1 || item.operation_status === 2) && item.status === 'saved')
  if (isPublishing || isMaking) {
    if (!refreshTimer) {
      refreshTimer = setInterval(() => {
        getData(false)
      }, 5000)
    }
  } else {
    if (refreshTimer) {
      clearInterval(refreshTimer)
      refreshTimer = null
    }
  }
}
getData()
watch(() => route.params.id, id => {
  if (!id) getData()
})
const isHasUsed = computed(() => {
  return list.value.some(v => v.status === 'use')
})
function updateconfigData(key, val) {
  baseConfig[formData.value.config.os][key] = val
}

function updateconfigMachineData(type, val) {
  baseConfig[type].machine = val
}
function updateSoftWare(v) {
  formModel.value.software = v
}
function deleteconfigData(key) {
  delete baseConfig[formData.value.config.os][key]
}
</script>
<style lang="scss" scoped>
[data-theme=light]{
  .images-list  {
    .top {
      background-image: url('~@/assets/img/images/no-auth-top-1-light.png');
    }
    .table-wrap {
      background: #FFFFFF;
      .list-wrap {
        :deep(.el-table__body-wrapper) {
          td {
            // background: #F8F9FA !important;
          }
        }
        :deep(.el-table__header) {
          th {
            background-color: #E8EAED !important;
          }
        }
      }
    }
  }
}
.images-list {
  width: 100%;
  .top {
    width: 100%;
    height: 200px;
    background: linear-gradient(0deg, #14151C 0%, rgba(16, 18, 27, 0) 31%);
    // background-color: #14151C;
    background-image: url('~@/assets/img/images/no-auth-top-1.png');
    background-repeat: no-repeat;
    background-size: auto 200px;
    background-position: right center;
    padding: 38px 48px;
    box-sizing: border-box;
    .top-title-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 10px !important;
    }
    &-title {
      font-size: 20px !important;
      font-weight: 500;
      line-height: 32px;
      // color: #C3CCD9 !important;
      color: var(--color-text-2) !important;
      width:fit-content;
    }
    svg {
      font-size: 18px;
      color: #9DA6B2;
      margin-left: 4px;
      &:hover {
        color: #00ab7a;
      }
    }
    p {
      margin: 0;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 0px;
      color: #767F8C;
    }
  }
  .carousel-wrapper {
    width: 1000px;
    margin: 0 auto;
  }
  .apply-btn {
    margin: 30px auto;
    display: block;
  }
  .imagesListForm {
    :deep(.el-form-item__label) {
      padding-right: 12px;
    }
  }
  .table-wrap {
    background: var(--color-bg-1);
    margin: 0 24px;
    padding: 24px;
    height: calc(100vh - 274px);
    box-sizing: border-box;
    .images-table {
      .dot {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 4px;
        position: relative;
        top: -2px;
      }
    }
    .list-wrap {
      height: calc(100% - 86px);
      overflow: auto;
      box-sizing: border-box;
      // :deep(.el-table .el-table__body-wrapper tbody tr:last-child td) {
      //   border-bottom: 1px solid transparent !important;
      // }
      // :deep(.el-table__inner-wrapper) {
      //   &:before {
      //     display: none !important;;
      //   }
      // }
      .table-operation-btn {
        margin-left: 16px;
        justify-content: flex-start !important;
      }
      .dropdown-wrapper:hover .arrow-icon {
        transform: rotate(180deg);
      }
      .arrow-icon {
        transition: transform 0.3s ease;
      }
      :deep(.el-table__body-wrapper) {
        td {
          // background: #1C1E26 !important;
        }
      }
      :deep(.el-table__header) {
        th {
          background-color: #2C2F3B !important;
        }
      }
      :deep(.el-table__body tr.hover-row>td.el-table__cell) {
        background: transparent !important;
      }
    }
    .pagination-wrap {
      margin-top: 16px;
      margin-right: -20px;
    }
  }
  .nodata {
    background: var(--color-bg-1);
    margin: 0 24px 24px 24px;
    height: calc(100vh - 280px);
    padding-top: 150px;
    box-sizing: border-box;
  }
}
</style>
