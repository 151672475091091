<template>
  <div class="makeImage">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item class="to-make-image" @click="back">镜像制作</el-breadcrumb-item>
      <el-breadcrumb-item>新增镜像</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="makeImage-container">
      <div style="font-size: 16px;" class="title">
        <el-button style="color: #9DA6B2;" type="text" @click="back" :icon="ArrowLeft"></el-button>
        <span>制作镜像</span>
      </div>
      <div class="content">
        <div class="header">
          <div class="left">
            <span>
              <el-button type="primary" :disabled="status === 'running' || isImageRunning" @click="showVnc">启动镜像</el-button>
              <el-button :loading="closeLoading" type="plain" v-if="status === 'running' || status === 'close' || isImageRunning" @click="closeVnc(false)">关闭镜像</el-button>
              <el-button :icon="Edit" type="text" @click="edit">编辑信息</el-button>
            </span>
            <el-tooltip class="item" :effect="$isDark() ? 'dark' : 'light'" content="全屏" placement="top">
              <span @click="handleFullScreen" style="font-size: 24px;cursor: pointer;" v-show="status === 'running'">
                <svg-icon name="quanping1"></svg-icon>
              </span>
            </el-tooltip>
          </div>
        </div>
        <p style="color: var(--color-text-2);">镜像编辑完成后，请关闭镜像保存编辑内容</p>
        <div class="vnc-content" :class="{connected: status === 'running'}">
          <div v-show="status === 'running'" class="vnc" id="vnc-system__wrap1" style="display: block;" ref="vnc"></div>
          <div v-show="status === 'close'" class="vnc vnc-stop">
            <img :src="getImg(`report/vnc/closing.png`)" alt="">
            <p>镜像关闭中...</p>
          </div>
          <div v-show="status === 'waiting' && isImageRunning" class="vnc vnc-isRunning" style="position:relative">
            <img :src="getImg(`report/vnc/isRunning.png`)" alt="">
            <p>当前镜像已启动，请手动 <span @click="showVnc(false)">连接镜像</span></p>
          </div>
          <div v-show="status === 'waiting' && !isImageRunning" class="vnc vnc-connecting" style="position:relative">
            <img :src="getImg(`report/vnc/connecting.png`)" alt="">
            <p>请启动镜像</p>
          </div>
          <div v-show="status === 'connecting'" class="vnc vnc-connecting">
            <img :src="require('@/assets/img/search/search-ing.png')" alt="">
            <p>正在连接...</p>
          </div>
          <div v-show="status === 'cancel'" class="vnc vnc-cancel">
            <img :src="getImg(`report/vnc/cancel.png`)" alt="">
            <p>连接已取消</p>
          </div>
          <div v-show="status === 'fail'" class="vnc vnc-fail">
            <img :src="getImg(`report/vnc/fail.png`)" alt="">
            <p>连接失败</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <images-info isEdit v-if="imagesInfoVisible" v-model:visible="imagesInfoVisible" :initialData="props.imageInfo"/>
</template>

<script setup>
import { ArrowLeft, Edit } from '@element-plus/icons-vue'
import ImagesInfo from './ImagesInfo.vue'
import { getImg } from 'app/style'
import { ref, defineProps } from 'vue'
import { startImage, saveImage } from 'services/custom-images'
import { useRoute, useRouter } from 'vue-router'
import { message } from '@/hooks/useUI'
import RFB from '@novnc/novnc/core/rfb'
import { ElMessageBox } from 'element-plus'
import { useState } from '@/hooks/useVuex'

const props = defineProps({
  imageInfo: {
    type: Object,
    default: () => ({})
  }
})
const { userInfo } = useState('user', ['userInfo'])
const rfb = ref(null)
const vnc = ref()
const route = useRoute()
const router = useRouter()
const status = ref('waiting')
const imagesInfoVisible = ref(false)
const closeLoading = ref(false)
const edit = () => {
  imagesInfoVisible.value = true
}
const isImageRunning = ref(props.imageInfo.operation_status === 2)
const imageId = route.params.id
const showVnc = (needStart = true) => {
  status.value = 'connecting'
  if (!needStart) {
    connectVnc()
    return
  }
  startImage({
    image_id: imageId
  }).then(res => {
    const { code, message: errMessage } = res
    if (code) {
      message(errMessage, 'error')
      status.value = 'fail'
      isImageRunning.value = false
    } else {
      connectVnc()
    }
  })
}

const closeVnc = (back = false) => {
  status.value = 'close'
  if (back) router.push('/usercenter/images')
  closeLoading.value = true
  saveImage({
    image_id: imageId
  }).then(res => {
    status.value = 'waiting'
    isImageRunning.value = false
    closeLoading.value = false
  })
}
const handleFullScreen = ev => {
  ev.stopPropagation()
  if (vnc.value.requestFullscreen) {
    vnc.value.requestFullscreen()
  } else if (vnc.value.webkitRequestFullscreen) {
    vnc.value.webkitRequestFullscreen()
  } else if (vnc.value.mozRequestFullscreen) {
    vnc.value.mozRequestFullscreen()
  } else if (vnc.value.msRequestFullscreen) {
    vnc.value.msRequestFullscree()
  }
}

const back = event => {
  if (status.value === 'running') {
    event.preventDefault()
    ElMessageBox.confirm(
      '返回上个页面后，当前镜像编辑信息可能会丢失，请确认是否返回',
      {
        confirmButtonText: '确认关闭',
        cancelButtonText: '取消'
      }
    )
      .then(() => {
        closeVnc(true)
      })
      .catch(() => {
      })
  } else {
    router.push('/usercenter/images')
  }
}
document.addEventListener('fullscreenchange', () => {
  if (!document.fullscreenElement) {
    // 已退出全屏
    // document.querySelector('#noVNC_mouse_capture_elem').click()
    document.querySelector('#noVNC_mouse_capture_elem').style.display = 'none'
  }
})
const isIntranet = window.location.host === 'ata.360zqaq.net'
const connectVnc = (token) => {
  vnc.value.innerHTML = ''
  const PASSWORD = 'xen360'
  // 公司k3s开发环境
  const _rfb = new RFB(vnc.value, `${isIntranet ? 'wss://ata.360zqaq.net' : process.env.VUE_APP_CUSTOM_IMAGE_API}/websockify/?token=CustomImageID.${imageId}&auth=${userInfo.value.api_token}`, {
  // const _rfb = new RFB(vnc.value, `wss://10.217.122.17:8360/websockify/?token=CustomImageID.${imageId}&auth=${userInfo.value.api_token}`, {
    credentials: { password: PASSWORD }
  })
  _rfb.addEventListener('connect', connectedToServer)
  _rfb.addEventListener('disconnect', disconnectedFromServer)
  _rfb.addEventListener('securityfailure', securityfailureFromServer)
  _rfb.scaleViewport = true
  _rfb.resizeSession = true
  _rfb.clipViewport = false
  _rfb.viewOnly = false
  rfb.value = _rfb
}
const connectedToServer = () => {
  status.value = 'running'
  setTimeout(() => {
    const dom = document.getElementsByTagName('canvas')[0]
    dom.style.width = '100%'
    dom.style.height = '100%'
    resizeCanvas()
  }, 100)
  const container = document.getElementById('vnc-system__wrap1')
  function resizeCanvas() {
    const containerHeight = container.clientHeight
    const aspectRatio = rfb.value._fbWidth / rfb.value._fbHeight
    const calculatedWidth = containerHeight * aspectRatio

    container.style.width = `${calculatedWidth}px`
  }
}
function exitFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.mozCancelFullScreen) { /* Firefox */
    document.mozCancelFullScreen()
  } else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
    document.webkitExitFullscreen()
  } else if (document.msExitFullscreen) { /* IE/Edge */
    document.msExitFullscreen()
  }
}
const disconnectedFromServer = (msg) => {
  // if (isManualDisconnect) return
  if (msg?.detail?.clean) {
    // emit('changeVncStatus', 'end')
    status.value = 'waiting'
    if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
      exitFullscreen()
    }
  } else {
  }
}
const securityfailureFromServer = (detail) => {
  console.log('验证失败', detail)
}
</script>
<style lang="scss" scoped>
[data-theme=light]{
  .makeImage {
    .makeImage-container {
      background: #fff;
    }
  }
}
.makeImage {
  position: absolute;
  z-index: 1000;
  width: calc(100% - 48px);
  background: var(--color-bg-2);
  padding: 0 24px;
  :deep(.el-breadcrumb) {
    height: 55px;
    line-height: 55px;
    .to-make-image {
       cursor: pointer;
       .el-breadcrumb__inner {
        color: var(--color-text-2) !important;
       }
       &:hover {
        .el-breadcrumb__inner {
          color: var(--color-priamry) !important;
        }
       }
    }
  }
  .makeImage-container {
    height: calc(100vh - 170px);
    padding: 18px 24px;
    background: #1C1E26;
    .title {
      display: flex;
      align-items: center;
      span {
        color: var(--color-text-2);
      }
    }
    .content {
      .header {
        .left {
          display: flex;
          align-items: center;
          justify-content: space-between;
          img {
            cursor: pointer;
          }
        }
      }
      .vnc-content {
        // min-height: 400px;
        margin-top: 10px;
        // background: rgb(40, 40, 40);
        border: 1px solid var(--color-border-2);
        &.connected {
          background: rgb(40, 40, 40);
        }
        .vnc {
          height: calc(100vh - 280px);
          max-width: 100%;
          margin: 0 auto;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          ::deep(div) {
            display: block !important;
          }
          &>img {
            width: 90px;
          }
          &.vnc-isRunning {
            span {
              cursor: pointer;
              color: var(--color-priamry);
            }
          }
        }
      }
    }
  }
}
</style>
