/* eslint-disable vue/no-mutating-props */
<template>
  <div class="configBox">
    <div :class="{ 'uploadConfig-right__config--disable': true }">
      <div style="margin-top:0" class="configBox-item">
        <div class="configBox-content">
          <!-- 操作系统 -->
            <div
              class="configBox-content__box configBox-content__box--half configBox-content__box--left"
            >
              <div class="configBox-content__box--title">
                {{$c('basic.os.title')}}
              </div>
              <div>
                <el-select
                  class="os-alias"
                  v-model="machine.system"
                  style="width: 400px;"
                  @change="e => changeimageConfig(e, 'system')"
                  :placeholder="placeholderList.system"
                  teleported
                  :suffix-icon="arrow"
                  popper-class="detect-operate"
                  :disabled="props.disabled"
                >
                  <el-option
                    v-for="item in getImageOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="configBox-content__box">
              <div class="configBox-content__box--title">
                {{$c('basic.software_major.title')}}
              </div>
              <div>
                <table-radio
                  :optionData="getSoftwareMajorOption"
                  style="width: 400px;"
                  v-model:value="machine.software_major"
                  @change="e => changeimageConfig(e, 'software_major')"
                  @disableClick="showUpgrade"
                  @_software="updateSoftWare"
                  :disabled="props.disabled"
                  :placeholder="softwareMajorPlaceholder"
                ></table-radio>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import tableRadio from './tableRadio'
import arrow from 'components/common/arrow'
import { useState } from '@/hooks/useVuex'
import { useStore } from 'vuex'
// import UpgradeDialog from '@/components/common/upgradeDialog'
import { defineProps, defineEmits, ref, toRefs, reactive, watch, nextTick, onMounted, computed, watchEffect } from 'vue'
import {
  getImages,
  getImagesTimezone,
  getImagesRegion
  // getImagesWkdir
} from 'services/detection'
import { configPermise, permiseLevel, fileType } from '@/views/detection/components/permise'
import { t } from 'app/i18n'

const store = useStore()
const { userInfo, scoreInfo } = useState('user', ['userInfo', 'scoreInfo'])
const gid = store.getters.gid

const props = defineProps({
  configData: {
    type: Object,
    default: () => ({
      // timeout: 120
    })
  },
  // 用户用了选项的权限都会记录在里面
  configUserPromise: {
    type: Object,
    default: () => {}
  },
  // 是否使用积分系统，现在单个任务,而且得登录才能使用，不使用的话提示框没有充积分的
  useIntegral: {
    type: Boolean,
    default: true
  },
  type: {
    type: String,
    default: 'file'
  },
  activeIndex: {
    default: null
  },
  taskType: {
    type: String,
    default: ''
  },
  curFileType: {
    type: String,
    default: ''
  },
  dataList: {
    type: Array,
    default: () => {
      return []
    }
  },
  includeUrlAndFile: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:configData', 'update:machineConfigData', 'delete:configData', 'inputError', 'change', 'updateSoftWare'])

const { configData, useIntegral, taskType } = toRefs(props)

const strategy = ref(configData.value.strategy || 2)
const cmd = ref(configData.value.cmd || '')

const pwd_to_zip = ref(configData.value.pwd_to_zip || '')
// const type_in_zip = ref(configData.value.type_in_zip || 'md5_in_zip')
// const in_zip = ref(configData.value.in_zip || '')
// const pwd_to_mso = ref(configData.value.pwd_to_mso || '')
// const office = ref(configData.value.office || 'mso')
// const emailer = ref(configData.value.emailer || 'extract')

// const browser = ref(configData.value.browser || '')
const custom_detect_type = ref(configData.value.custom_detect_type)
const _function = ref(configData.value.function || '')
const _arguments = ref(configData.value.arguments || '')
const name_forced = ref(configData.value.name_forced || '')
const ext_forced = ref(configData.value.ext_forced || '')
const runpath = ref(configData.value.runpath || '')
const timeout = ref(configData.value.timeout || 120)
const human = ref(configData.value.human || true)
const human_popbox = ref(configData.value.human_popbox || true)
const human_scroll = ref(configData.value.human_scroll || true)
const human_curpos = ref(configData.value.human_curpos || true)
const human_autoin = ref(configData.value.human_autoin || true)
// const fake_reboot = ref(configData.value.fake_reboot || true)
// const fast_detect = ref(configData.value.fast_detect || true)
const human_clipbd = ref(configData.value.human_clipbd || true)
// const pwd_forced = ref(configData.value.pwd_forced || true)

// const active_reboot = ref(configData.value.active_reboot || false)
// const env_var = ref(configData.value.env_var || [])
const internet = ref(configData.value.internet !== undefined ? configData.value.internet : true)
const fakenet = ref(configData.value.fakenet || false)
const decrypt_https = ref(props.configData.decrypt_https || false)
// setTimeout(() => {
//   console.log(props.configData)
// }, 1000)
// const timezone = ref(configData.value.timezone || '')
const datetime = ref(configData.value.datatime || '')
// const geo = ref(configData.value.ge || '')

const vcpus = ref(configData.value.vcpus || '')
const memory = ref(configData.value.memory || '')
const wnd_caption = ref(configData.value.wnd_caption || '')
const wnd_clsname = ref(configData.value.wnd_clsname || '')
const disk_volumn = ref(configData.value.disk_volumn || '')
const computer_name = ref(configData.value.computer_name || '')
const user_name = ref(configData.value.user_name || '')
const reg_key = ref(configData.value.reg_key || '')
const reg_subkey = ref(configData.value.reg_subkey || '')
const reg_name = ref(configData.value.reg_name || '')
const reg_type = ref(configData.value.reg_type || 'REG_SZ')
const reg_value = ref(configData.value.reg_value || '')
const proc_name = ref(configData.value.proc_name || '')
const script_str = ref(configData.value.script_str || '')
const dll_loader = ref(configData.value.dll_loader || '')

function updateProps(value, label) {
  emit('update:configData', label, value)
}

function updateSoftWare(v) {
  emit('updateSoftWare', v)
}
const $c = computed(params => {
  return (params) => {
    return t(`config.${params}`)
  }
})

watchEffect(() => {
  // const arr = ['strategy', 'cmd', 'custom_detect_type', 'name_forced', 'ext_forced', 'runpath', 'timeout', 'active_reboot', 'human', 'timezone', 'geo', 'datetime', 'env_var', 'decrypt_https', 'internet']
  strategy.value = configData.value.strategy
  cmd.value = configData.value.cmd
  pwd_to_zip.value = configData.value.pwd_to_zip

  // type_in_zip.value = configData.value.type_in_zip
  // in_zip.value = configData.value.in_zip
  // pwd_to_mso.value = configData.value.pwd_to_mso
  // office.value = configData.value.office
  // emailer.value = configData.value.emailer
  custom_detect_type.value = configData.value.custom_detect_type
  _function.value = configData.value.function
  _arguments.value = configData.value.arguments
  name_forced.value = configData.value.name_forced
  ext_forced.value = configData.value.ext_forced
  runpath.value = configData.value.runpath
  // timeout.value = configData.value.timeout
  human.value = configData.value.human
  human_popbox.value = configData.value.human_popbox
  human_scroll.value = configData.value.human_scroll
  human_curpos.value = configData.value.human_curpos
  human_autoin.value = configData.value.human_autoin
  // fake_reboot.value = configData.value.fake_reboot
  // fast_detect.value = configData.value.fast_detect
  human_clipbd.value = configData.value.human_clipbd
  // pwd_forced.value = configData.value.pwd_forced

  // active_reboot.value = configData.value.active_reboot
  // timezone.value = configData.value.timezone
  // geo.value = configData.value.geo
  datetime.value = configData.value.datetime

  vcpus.value = configData.value.vcpus
  memory.value = configData.value.memory
  wnd_caption.value = configData.value.wnd_caption
  wnd_clsname.value = configData.value.wnd_clsname
  disk_volumn.value = configData.value.disk_volumn
  computer_name.value = configData.value.computer_name
  user_name.value = configData.value.user_name
  reg_key.value = configData.value.reg_key
  reg_subkey.value = configData.value.reg_subkey
  reg_name.value = configData.value.reg_name
  reg_type.value = configData.value.reg_type
  reg_value.value = configData.value.reg_value
  proc_name.value = configData.value.proc_name
  script_str.value = configData.value.script_str
  dll_loader.value = configData.value.dll_loader

  // env_var.value = configData.value.env_var
  decrypt_https.value = configData.value.decrypt_https
  internet.value = configData.value.internet
  fakenet.value = configData.value.fakenet
})

// const isShowUpgrade = ref(false)
// const showVersionDetails = ref(false)
// const isOpen = ref([])
// const images = ref([])
// const softwareMajorPlaceholder = ref('请选择')

// const emit = defineEmits(['inputError', 'change'])

const state = reactive({
  // isShowUpgrade: false,
  showVersionDetails: false,
  isOpen: [],
  showConfig: {},
  images: [],
  softwareMajorPlaceholder: t('config.basic.software_major.placeholder'),
  machine: {
    system: '',
    lang: '',
    software_major: ''
  },
  fileTypeOption: [],
  timezoneOption: [],
  regionOption: [],
  wkdirOption: [],
  torOption: [],
  vpnOption: [],
  proxyOption: [],
  exitLocationOption: [],
  inputError: {
    user_agent: {
      value: false,
      regular: /[^\x00-\xff]/g // eslint-disable-line
    },
    function: {
      value: false,
      regular: /[^\x00-\xff]/g // eslint-disable-line
    },
    arguments: {
      value: false,
      regular: /[^\x00-\xff]/g // eslint-disable-line
    },
    name_forced: {
      value: false,
      regular: /(\\)|(\/)|(:)|(\*)|(\?)|(\")|(\<)|(\>)|(\|)|[^\x00-\xff]/g // eslint-disable-line
    },
    ext_forced: {
      value: false,
      regular: /(\\)|(\/)|(:)|(\*)|(\?)|(\")|(\<)|(\>)|(\|)|[^\x00-\xff]/g // eslint-disable-line
    },
    pwd_to_zip: {
      value: false,
      regular: /(\s)|[^\x00-\xff]/g // eslint-disable-line
    },
    vcpus: {
      value: false,
      regular: /^\\d+$/g // eslint-disable-line
    },
    memory: {
      value: false,
      regular: /^\\d+$/g // eslint-disable-line
    },
    wnd_caption: {
      value: false,
      regular: /(\s)|[^\x00-\xff]/g // eslint-disable-line
    },
    wnd_clsname: {
      value: false,
      regular: /(\s)|[^\x00-\xff]/g // eslint-disable-line
    },
    disk_volumn: {
      value: false,
      regular: /(\s)|[^\x00-\xff]/g // eslint-disable-line
    },
    computer_name: {
      value: false,
      regular: /(\s)|[^\x00-\xff]/g // eslint-disable-line
    },
    user_name: {
      value: false,
      regular: /(\s)|[^\x00-\xff]/g // eslint-disable-line
    },
    reg_key: {
      value: false,
      regular: /(\s)|[^\x00-\xff]/g // eslint-disable-line
    },
    reg_subkey: {
      value: false,
      regular: /(\s)|[^\x00-\xff]/g // eslint-disable-line
    },
    reg_name: {
      value: false,
      regular: /(\s)|[^\x00-\xff]/g // eslint-disable-line
    },
    reg_type: {
      value: false,
      regular: /(\s)|[^\x00-\xff]/g // eslint-disable-line
    },
    reg_value: {
      value: false,
      regular: /(\s)|[^\x00-\xff]/g // eslint-disable-line
    },
    proc_name: {
      value: false,
      regular: /(\s)|[^\x00-\xff]/g // eslint-disable-line
    }
  },
  _configPermise: {
    timeoutMax: 60
  },
  timeoutMin: 60
})

const { isOpen, showConfig, images, softwareMajorPlaceholder, machine, fileTypeOption, timezoneOption, regionOption, wkdirOption, inputError, _configPermise } = toRefs(state)

// 当前用户拥有的权限，可能比之前的权限高
const getUserPermise = computed(() => {
  // 只有使用积分系统才会提高权限
  if (useIntegral.value) {
    if (scoreInfo.value?.detection_enter_last > 0 && userInfo.value.gid < 4) {
      return 'enterprise'
    }
    if (scoreInfo.value?.detection_pro_last > 0 && userInfo.value.gid < 3) {
      return 'professional'
    }
  }
  return userInfo.value.group
})

const is64 = computed(() => props.curFileType.includes('64'))

const forceQuickCheck = computed(() => {
  return !showConfig.value.default_quick_detect && (taskType.value === 'Android' || is64.value)
})

const placeholderList = computed(() => {
  return {
    system: getImageOption.value[0] && getImageOption.value[0].label,
    lang: (getLangOption.value[0] && getLangOption.value[0].label) || t('config.basic.lang.zh_label')
  }
})

const getImageOption = computed(() => {
  const group = getUserPermise.value || 'visitor'
  let data2 = []
  images.value.forEach((i) => {
    if (data2.findIndex((t) => t.value === `${i.os};${i.major};${i.arch}`) === -1) {
      const label = `${i.os} ${i.major} ${i.arch}${t('config.basic.os.suffix')}`
      const value = `${i.os};${i.major};${i.arch}`
      data2.push({
        label,
        value,
        disabled: !configPermise[group].system.option.includes(value)
      })
    }
  })
  // 提交64位文件时屏蔽32位操作系统
  if (is64.value) {
    data2 = data2.filter(v => v.value.includes('64'))
  }

  data2.sort((a, b) => {
    const splita = a.value.split(';')
    const splitb = b.value.split(';')
    const verA = parseInt(splita[1])
    const digitA = parseInt(splita[2])
    const verB = parseInt(splitb[1])
    const digitB = parseInt(splitb[2])
    return verA !== verB ? verA - verB : digitA - digitB
  })
  return data2
})
const getLangOption = computed(() => {
  const [os, major, arch] = machine.value.system.split(';')
  const langList = [
    ...new Set(
      images.value
        .filter((i) => i.os === os && i.major === major && i.arch === arch)
        .map((i) => i.lang)
    )
  ]
  const keyMap = {
    'zh-cn': {
      label: t('config.basic.lang.zh_label'),
      disabled: false
    },
    'en-us': {
      label: t('config.basic.lang.en_label'),
      // disabled: !getUserPermise.value || getUserPermise.value === 'free'
      disabled: false
    }
  }
  if (images.value.length === 1) {
    return [
      {
        label: keyMap[images.value[0].lang].label,
        value: images.value[0].lang,
        disabled: false
      }
    ]
  }
  return langList.map((i) => ({
    label: keyMap[i].label,
    value: i,
    disabled: keyMap[i].disabled
  }))
})

const getSoftwareMajorOption = computed(() => {
  let os, major, arch, lang
  if (taskType.value === 'Linux') {
    [os, major, arch, lang] = ['Ubuntu', '18.04', '64', 'en-us']
  } else if (taskType.value === 'Android') {
    [os, major, arch, lang] = ['Android', '11', '64', 'en-us']
  } else {
    [os, major, arch, lang] = ['Windows', '7 SP1 Pro', '32', 'zh-cn']
  }
  const group = getUserPermise.value || 'visitor'
  const levels = permiseLevel[group]
  if (machine.value.system) {
    [os, major, arch] = machine.value.system.split(';')
  }
  if (machine.value.lang) {
    lang = machine.value.lang
  }
  const data = images.value
    .filter(
      (item) =>
        item.os === os && item.major === major && item.arch === arch && item.lang === lang && !item.custom_name
    )
    .map((item) => ({
      value: item.image_name,
      label: item.software.map((item) => `${item.alias} ${item.major}`).join('; '),
      software: item.software.map((item) => ({
        alias: item.alias,
        major: item.major
      })),
      disabled: !levels.includes(item.level),
      level: item.level,
      _software: item.software
    }))
  return data.sort((a, b) => a.level - b.level)
})

watch([taskType], (v) => {
  // if (v[0] === null) return
  getImagesOption()
})
watch([userInfo, getUserPermise, taskType], () => setShowConfig())
watch(timeout, (data) => {
  if (data > _configPermise.value.timeoutMax) {
    nextTick(() => {
      timeout.value = _configPermise.value.timeoutMax
      changeConfig(_configPermise.value.timeoutMax, 'timeout')
      // showUpgrade()
    })
  }
}, { immediate: false })

// watch(() => configData.value.fakenet, (v) => {
//   internet.value = !v;
// });

onMounted(() => {
  getImagesOption()
  getImagesFiletypeOption()
  getImagesTimezoneOption()
  getImagesRegionOption()
  getImagesWkdirOption()
  setShowConfig()
  // getConstants()
  // 设置timeout的默认值
  // nextTick(() => {
  //   configData.timeout = 120
  // })
})

function setShowConfig() {
  const group = getUserPermise.value || 'visitor'
  for (const key in configPermise[group]) {
    showConfig.value[key] = !configPermise[group][key].disable
  }
  setConfigPermise(group)
}
// 设置一些特殊配置项的权限
function setConfigPermise(group) {
  _configPermise.value.timeoutMax = configPermise[group].timeout.max
  if ((taskType.value === 'Android' || is64.value) && gid <= 1) {
    strategy.value = configPermise[group].default_quick_detect.strategy
    changeConfig(configPermise[group].default_quick_detect.strategy, 'strategy') // 初始化strategy的值，设置是否使用权限图标
  }
}
function setMachine() {
  isOpen.value = []
  // 不需要有默认值,默认值用placeholder显示
  if (configData.value.machine) {
    const image = images.value.find((item) => configData.value.machine === item.image_name)
    if (image) {
      machine.value = {
        system: `${image.os};${image.major};${image.arch}`,
        lang: image.lang,
        software_major: image.image_name
      }
    }
  } else {
    machine.value = {
      system: '',
      lang: '',
      software_major: ''
    }
    const images = getImageOption
    const langs = getLangOption
    // console.log(images)
    const majors = getSoftwareMajorOption.value
    setTimeout(() => {
      if (images.value && images.value.length) {
        machine.value.system = images.value[0].value
        // machine.value.lang = 'zh-cn'
        if (langs.value && langs.value.length) {
          machine.value.lang = langs.value[0].value
        }
      }
    }, 500)
    // console.log(majors.value)
    if (majors && majors.length) {
      if (is64.value && !forceQuickCheck.value) { // 专业版提交64位文件手动选择一下64位machine，免费版则强制快速检测
        updateProps(false, 'useBase')
      }
      // machine.software_major = majors[0].value
      softwareMajorPlaceholder.value = getSoftwareMajorOption.value[0].label || t('config.basic.software_major.non_select')
    } else {
      softwareMajorPlaceholder.value = t('config.basic.software_major.non_select')
    }
    // 都没有选的时候不要给默认值
    // configData.machine = getSoftwareMajorOption[0].value
    // machine.software_major = getSoftwareMajorOption[0].value
  }
}

function changeConfig(data, label, intercept = false) {
  !intercept && updateProps(data, label)
  // 输入框错误检测
  if (inputError.value[label]) {
    const item = inputError.value[label]
    if (label === 'vcpus') {
      item.value = !/^\+?[1-4]$/.test(data) && data
    } else if (label === 'memory') {
      item.value = !(/^\+?[1-9][0-9]{3}$/.test(data) && parseInt(data) > 1023 && parseInt(data) < 4097) && data
    } else if (label === 'reg_value') {
      if (reg_type.value === 'REG_DWORD') {
        item.value = !/^\+?[1-9][0-9]*$/.test(data) && data
      } else {
        item.value = data.match(item.regular)
      }
    } else if (label === 'reg_type') {
      inputError.value.reg_value.value = false
    } else {
      item.value = data.match(item.regular)
    }
    // 如果存在一个为true的就暴露出错误
    let error = false
    for (const key in inputError.value) {
      if (inputError.value[key].value) {
        error = true
        break
      }
    }
    emit('inputError', error)
  }
  if (label === 'human' && !data) {
    updateProps(false, 'human_scroll')
    human_scroll.value = false
    updateProps(false, 'human_curpos')
    human_curpos.value = false
    updateProps(false, 'human_autoin')
    human_autoin.value = false
    updateProps(false, 'human_clipbd')
    human_clipbd.value = false
    updateProps(false, 'human_popbox')
    human_popbox.value = false
  }
  if (label === 'reg_type') {
    reg_value.value = ''
    updateProps('', 'reg_value')
  }
  emit('change')
}

function changeimageConfig(data, type) {
  changeConfig(data, type)
  if (type === 'software_major') {
    // 如果改变应用环境
    // configData.machine = machine.value.software_major
    updateProps(machine.value.software_major, 'machine')
  } else {
    if (type === 'system') {
      if (getLangOption.value.map(item => item.value).indexOf(machine.value.lang) === -1) {
        machine.value.lang = getLangOption.value[0].value
      }
    }
    // 如果改变其他的就重置应用环境
    machine.value.software_major = ''
    // emit('updateSoftWare', getSoftwareMajorOption[0])
    // 使用下拉选项第一个
    if (getSoftwareMajorOption.value[0]) {
      softwareMajorPlaceholder.value = getSoftwareMajorOption.value[0].label || t('config.basic.software_major.non_select')
      updateProps(getSoftwareMajorOption.value[0].value, 'machine')
    } else {
      softwareMajorPlaceholder.value = t('config.basic.software_major.non_select')
      updateProps('', 'machine')
    }
    // machine.software_major = getSoftwareMajorOption[0].value
  }
}
function getImagesOption() {
  const _type = taskType.value === 'Linux' ? { os: 'Linux' } : taskType.value === 'Android' ? { os: 'Android' } : taskType.value === 'MacOS' ? { os: 'MacOS' } : { os: 'Windows' }
  getImages(_type)
    .then((res) => {
      images.value = res
      setMachine()
      // updateMachineProps(res[0].image, _type)
      updateProps(getSoftwareMajorOption.value[0].value, 'machine')
    })
    // .catch(() => {
    //   store.commit('app/showMessage', {
    //     type: 'error',
    //     message: t('detection.submit_failed'),
    //     duration: 1000
    //   })
    // })
}
function getImagesFiletypeOption() {
  fileTypeOption.value = fileType.map((item) => ({
    label: item,
    value: item
  }))
  fileTypeOption.value.unshift({
    label: t('config.dynamic.custom_detect_type.placeholder'),
    value: ''
  })
}
function getImagesWkdirOption() {
  // getImagesWkdir()
  // .then(res => {
  //   wkdirOption = res.map(item =>({
  //     label: item,
  //     value: item
  //   }))
  // })
  // .catch(() => {
  //   $store.commit('SHOWMESSAGE', { type: 'error', message: '提交失败，网络延时，请重新提交', duration: 1000 })
  // })
  wkdirOption.value = [
    {
      label: t('config.dynamic.runpath.options.auto'),
      value: ''
    },
    {
      label: t('config.dynamic.runpath.options.desktop'),
      value: 'desktop'
    },
    {
      label: t('config.dynamic.runpath.options.document'),
      value: 'document'
    },
    {
      label: t('config.dynamic.runpath.options.user'),
      value: 'user'
    },
    {
      label: t('config.dynamic.runpath.options.download'),
      value: 'download'
    },
    {
      label: t('config.dynamic.runpath.options.appdata'),
      value: 'appdata'
    },
    {
      label: t('config.dynamic.runpath.options.temp'),
      value: 'temp'
    },
    {
      label: t('config.dynamic.runpath.options.windows'),
      value: 'windows'
    },
    {
      label: t('config.dynamic.runpath.options.rootdir'),
      value: 'rootdir'
    }
  ]
}
function getImagesRegionOption() {
  getImagesRegion()
    .then((res) => {
      regionOption.value = res.map((item) => ({
        label: item[1],
        value: item[0]
      }))
      regionOption.value.unshift({
        label: t('config.strategy.geo.placeholder'),
        value: ''
      })
    })
    .catch(() => {
      store.commit('app/showMessage', {
        type: 'error',
        message: t('detection.submit_failed'),
        duration: 1000
      })
    })
}
function getImagesTimezoneOption() {
  getImagesTimezone()
    .then((res) => {
      timezoneOption.value = res.map((item) => ({
        label: item[1],
        value: item[0]
      }))
      timezoneOption.value.unshift({
        label: t('config.strategy.timezone.placeholder'),
        value: ''
      })
    })
    .catch(() => {
      store.commit('app/showMessage', {
        type: 'error',
        message: t('detection.submit_failed'),
        duration: 1000
      })
    })
}
// function showUpgrade(type) {
//   if (type === false) return
//   isShowUpgrade.value = true
// }

</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';
[data-theme='light'] {
  .configBox-footer {
    border-color: #E8EAED !important;
  }
}

.configBox  {
  position: relative;
  margin-bottom: 20px;
  .quick-check-mask  {
    position: absolute;
    top: 98px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--detect-config-mask);
    transition: background 0.5s;
    z-index: 1000;
  }
  .quick-check-info  {
    position: relative;
    top: 1px;
  }
  &-inputMsg  {
    position: relative;
    &:after  {
      content: '\8bf7\8f93\5165\6b63\786e\7684\5b57\7b26\4e32';
      position: absolute;
      left: 0;
      top: 35px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      color:#F06D67;
    }
  }
  &-inputMsg-cpu {
    position: relative;
    &:after  {
      content: '\8bf7\8f93\5165\0031\002d\0034\7684\6b63\6574\6570';
      position: absolute;
      left: 0;
      top: 35px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      color:#F06D67;
    }
  }
  &-inputMsg-reg  {
    position: relative;
    &:after  {
      content: '\8bf7\8f93\5165\6b63\6574\6570';
      position: absolute;
      left: 0;
      top: 35px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      color:#F06D67;
    }
  }
  &-inputMsg-memory {
    position: relative;
    &:after  {
      content: '\8bf7\8f93\5165\0031\0030\0032\0034\002d\0034\0030\0039\0036\7684\6b63\6574\6570';
      position: absolute;
      left: 0;
      top: 35px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      color:#F06D67;
    }
  }
  &-item  {
    margin-top: 16px;
    &:last-child  {
      & .configBox-footer  {
        border-bottom: none;
      }
    }
  }
  &-title  {
    height: 22px;
    line-height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-text-1);
    &__icon  {
      height: 16px;
      margin:0 4px -3px 0;
    }
  }
  .quick-check  {
    color: var(--color-text-1);
    margin: 16px 0 6px;
  }
  &-footer  {
    border-bottom: 1px dashed;

    border-color: var(--detect-dividing-line);
    padding-bottom: 16px;
    text-align: center;
    &__img  {
      width: 20px;
      margin-top: 16px;
      transform: rotate(180deg);
      transition: transform 0.5s;
      filter: var(--svg-filter3);
      cursor: pointer;
      &--open  {
        transform: rotate(0deg);
      }
      &:hover  {
        filter: var(--svg-filter4);
      }
    }
  }
  &-content  {
    & :deep(.el-radio-group){
      .el-radio__label {
        color: var(--color-text-2);
      }
    }
    &__box  {
      margin-top: 20px;
      display: flex;
      align-items: center;
      :deep(.el-input__inner) {
        background-color: transparent !important;
      }
      :deep(.systemDetail) {
        top: 284px;
      }
      &--width200  {
        // width: 200px;
      }
      &--flex  {
        width: 207px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &--half  {
        // width: calc(50% - 8px);
        // display: inline-block;
      }
      &--left  {
        margin-right: 16px;
      }
      &--title  {
        height: 20px;
        line-height: 20px;
        margin-bottom: 6px;
        min-width: 80px;
        text-indent: 10px;
        color: var(--color-text-1);
        & img  {
          transform: translateY(1px);
        }
      }
      &--title2  {
        height: 20px;
        line-height: 20px;
        margin-bottom: 6px;
        padding-left: 12px;
        position: relative;
        color: var(--color-text-1);
        &:before  {
          content: ' ';
          height: 6px;
          width: 6px;
          border-radius: 3px;
          background-color: var(--color-priamry);
          position: absolute;
          left: 0;
          top: 7px;
        }
        & span  {
          margin-left: 5px;
          cursor: pointer;
          color: var(--color-priamry);
        }
      }
      &--switchTitle  {
        height: 20px;
        // margin-right: 40px;
        line-height: 20px;
        display: inline-block;
      }
      &--checkboxTitle  {
        margin-bottom: 20px;
        height: 20px;
        & span  {
          display: inline-block;
          width: 334px;
          float: right;
          border-bottom: 1px dashed #4D588B;
          margin-top: 9px;
        }
      }
      &--next  {
        padding-left: 20px;
      }
      &--radioChild  {
        width: calc(100% - 20px);
        margin: 6px 0 20px 20px;
        &:last-child  {
          margin-bottom: 0px;
        }
      }
      &--input120  {
        width: calc(25% - 4px);
        margin-right: 8px;
        &.el-select {
          width: calc(35% - 4px) !important;
        }
      }
      &--input356  {
        width: calc(75% - 4px);
        &.el-select__later {
          width: calc(65% - 4px) !important;
        }
      }
      &--input336  {
        width: calc(65% - 4px);
        margin-right: 8px;
      }
      &--input140  {
        width: calc(35% - 4px);
      }
      &--select82  {
        width: calc(20% - 5px) !important;
        margin-right: 8px;
      }
      &--input162  {
        width: calc(38% - 5px);
        margin-right: 8px;
      }
      &--input284 {
        width: calc(62% - 3px);
      }
      &--input184  {
        width: calc(42% - 6px);
      }
      &--item  {
        margin-top: 20px;
      }
      &--key  {
        display: inline-block;
        width: 50px;
        font-size: 14px;
      }
      &--value  {
        width: calc(100% - 50px);
      }
      &--disable  {
        position: relative;
        cursor: pointer !important;
        &::before  {
          content: ' ';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background-color: var(--detect-config-mask);
          z-index: 1001;
        }
      }
      & :deep(.el-select){
        @include select();
        .el-select-dropdown__item.is-disabled  {

          cursor: pointer !important;
        }
      }
       &  :deep(.el-date-editor){
        width: 100%;
        position: relative;
        &:after {
          position: absolute;
          right: 10px;
          top: 2px;
          pointer-events: none;
        }
        & .el-input__prefix, & .el-popper__arrow  {
          display: none;
        }
      }
      &  :deep(.dateTimePicker){
        @include dateEditor();
      }
      & :deep(.el-input){
        @include input();
      }
      & :deep(.el-switch){
        @include switch();
        &.is-disabled .el-switch__core, &.is-disabled .el-switch__label  {
          cursor: pointer !important;
        }
      }
      & :deep(.el-slider){
        @include slider();
      }
      & :deep(.el-checkbox){
        @include checkbox();
      }
      & :deep(.el-radio-group){
        @include radioGroup();
        & .el-radio  {
          display: inline-block;
          width: 100%;
          margin-top: 20px;
          &:first-child  {
            margin-top: 0;
          }
        }
      }
    }
  }
  .slider-wrapper {
    display: flex;
    & > span {
      line-height: 32px;
      padding-left: 8px;
    }
  }
}
</style>
