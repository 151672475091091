<template>
  <el-drawer
    title="发布记录"
    v-model="localVisible"
    size="670px"
    @close="onCancel"
    :destroy-on-close="true"
  >
    <el-table
      :data="historyList"
      style="width: 100%"
      :row-class-name="getRowClassName"
      :loading="loading"
      @sort-change="handleSortChange"
      :max-height="'calc(100vh - 200px)'"
    >
      <el-table-column prop="image_name" label="发布镜像" width="160">
        <template #default="scope">
          <div style="display: flex;align-items: center;">
            <img style="width: 20px;margin-right: 4px;" :src="require('@/assets/img/images/window.png')" alt="">
            <span>{{scope.row?.image_name}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" width="160">
        <template #default="scope">
          <div style="display: flex;align-items: center;">
            <span :style="{'background-color': scope.row?.status !== 'failed' ? '#00B38A' : '#F5222D'}" style="width: 6px;height: 6px;border-radius: 50%;margin-right: 4px"></span>
            <span>{{scope.row?.status !== 'failed' ? '发布成功' : '发布失败'}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column sortable="custom" prop="created_at" label="发布时间">
      </el-table-column>
      <el-table-column prop="created_by" label="发布人" width="120"/>
    </el-table>
    <Pagination
      v-if="total > 10"
      style="margin-top: 20px;"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :pageSizes="[10, 50]"
      :totalSize="total"
      :current_page="page"
      :page-size="size"
    />
  </el-drawer>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from 'vue'
import { getHistory } from 'services/custom-images'
import Pagination from '@/components/common/Pagination'

const props = defineProps({
  visible: Boolean
})
const historyList = ref([])
const emit = defineEmits(['update:visible', 'submit'])
const loading = ref(false)
const localVisible = ref(props.visible)
const order = ref('desc')
const page = ref(1)
const size = ref(10)
const total = ref(0)
watch(
  () => props.visible,
  (newVal) => {
    localVisible.value = newVal
  }
)

watch(
  () => localVisible.value,
  (newVal) => {
    emit('update:visible', newVal)
  },
  { immediate: true }
)
const handleSortChange = ({ prop, order: _order }) => {
  order.value = _order === 'ascending' ? 'asc' : 'desc'
  page.value = 1
  fetchData()
}
const fetchData = () => {
  loading.value = true
  getHistory({
    order: order.value,
    page: page.value,
    size: size.value
  }).then(res => {
    historyList.value = res.content
    total.value = res.total
    loading.value = false
  })
}
fetchData()
const handleSizeChange = (_size) => {
  size.value = _size
  handleCurrentChange(1)
}
const handleCurrentChange = (_page) => {
  page.value = _page
  fetchData()
}
// 动态设置每行的类名
const getRowClassName = () => 'align-top'

// 取消
const onCancel = () => {
  localVisible.value = false
}
</script>

<style lang="scss" scoped>
.drawer-footer {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  right: 24px;
}

:deep(.align-top) {
  .el-table__cell {
    vertical-align: top; // 单元格内容顶部对齐
  }
}

.cell-content {
  white-space: normal; // 允许换行
  word-break: break-word; // 单词过长时换行
}

:deep(.el-table__header) {
  th {
    background-color: var(--color-bg-4) !important;
  }
}
</style>
