import request from 'app/http'

const getAuthentication = () => {
  return request.get('/authentication/custom')
}
const postaddImages = (params) => {
  return request.post('/images/custom', params)
}
const getImages = (params) => {
  return request.get('/images/custom', { params })
}
const getImageById = (id) => {
  return request.get('/images/custom/' + id)
}
const getFtp = (params) => {
  return request.get(`/images/custom/ftp`, { params })
}
const saveImage = (params) => {
  return request.post('/images/custom/operate/close', params)
}
const startImage = (params) => {
  return request.post('/images/custom/operate/start', params)
}
const getHistory = (params) => {
  return request.get(`/images/custom/publish/records`, { params })
}
const deleteImage = (params) => {
  return request.post('/images/custom/status/delete', params)
}
const publishImage = (params) => {
  return request.post('/images/custom/status/published', params)
}
const useImage = (params) => {
  return request.post('/images/custom/status/use', params)
}
const unuseImage = (params) => {
  return request.post('/images/custom/status/unuse', params)
}
export {
  getAuthentication,
  postaddImages,
  getImages,
  getImageById,
  getFtp,
  saveImage,
  startImage,
  getHistory,
  deleteImage,
  publishImage,
  useImage,
  unuseImage
}
