<template>
  <div class="images-wrapper" v-if="!loading">
    <no-auth v-if="noauth"/>
    <div v-else style="position: relative;">
      <images-list style="position: absolute;"/>
      <make-image v-if="imageId" :imageInfo="imageInfo" style="position: absolute;"/>
    </div>
  </div>
</template>

<script setup>
import { getAuthentication } from 'services/custom-images'
import NoAuth from './components/NoAuth.vue'
import ImagesList from './components/ImagesList.vue'
import MakeImage from './components/MakeImage.vue'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import emitter from 'app/emitter'

const route = useRoute()
const noauth = ref(false)
const imageId = ref(0)
const loading = ref(true)
const imageInfo = ref({})
watch(() => route.params.id, () => {
  imageId.value = route.params.id
})
emitter.on('imageInfo', row => {
  imageInfo.value = row
})
getAuthentication().then(res => {
  loading.value = false
  // res.view_image = true
  if (Object.keys(res).length === 0) {
    noauth.value = true
  }
  noauth.value = !res.view_image
})
</script>
<style lang="scss" scoped>
.images-wrapper {
  // padding: 0 24px;
  min-height: calc(100% - 60px);
}
</style>
