<template>
  <el-drawer
    v-model="localVisible"
    size="800px"
    @close="onCancel"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <template #header>
      <div class="my-header" style="position: relative;top: 3px;font-size: 16px;">
        {{ `${isEdit?'编辑':''}镜像信息` }}
        <span @click="isExampleShow = true" style="color: #00ab7a; font-size: 14px; margin-left: 10px;cursor: pointer;">镜像应用示例</span>
      </div>
    </template>
    <p style="font-size: 12px;color: var(--color-text-2);margin: -8px 0 24px;">编辑的镜像信息应用到沙箱云，使用web页面/API提交任务时可以使用</p>
    <el-form :model="form" :rules="rules" ref="formRef" label-width="100px" label-position="top" class="custom-form">
      <!-- 项目名称 -->
      <el-form-item label="操作系统" prop="os_alias">
        <el-input
          v-model="form.os_alias"
          maxlength="100"
          placeholder="请输入"
          :readonly="!isEdit"
        />
        <span v-if="nameError" class="error-text">该项目名称已被占用，请输入新的名称</span>
      </el-form-item>
      <el-form-item label="应用环境">
        <div v-for="(item, index) in form.software" :key="index" style="display: flex; gap: 10px;margin-bottom: 16px;">
          <el-form-item
            :prop="`software[${index}].name`"
            :rules="rules.software[index]?.name || []"
            :label="!index ? '名称' : ''"
            style="flex: 4;"
          >
            <el-input
              v-model="item.name"
              placeholder="请输入"
              maxlength="30"
              :readonly="!isEdit"
            ></el-input>
          </el-form-item>

          <el-form-item
            :prop="`software[${index}].alias`"
            :rules="rules.software[index]?.alias || []"
            :label="!index ? '缩写' : ''"
            style="flex: 2;"
          >
            <el-input
              v-model="item.alias"
              placeholder="请输入"
              maxlength="30"
              :readonly="!isEdit"
            ></el-input>
          </el-form-item>

          <el-form-item
            :prop="`software[${index}].major`"
            :rules="rules.software[index]?.major || []"
            :label="!index ? '产品版本号' : ''"
            style="flex: 3;"
          >
            <el-input
              v-model="item.major"
              placeholder="请输入"
              maxlength="30"
              :readonly="!isEdit"
            ></el-input>
          </el-form-item>

          <el-form-item
            :prop="`software[${index}].build`"
            :rules="rules.software[index]?.build || []"
            :label="!index ? '构建版本号' : ''"
            style="flex: 3;"
          >
            <el-input
              v-model="item.build"
              placeholder="请输入"
              maxlength="30"
              :readonly="!isEdit"
            ></el-input>
          </el-form-item>
          <svg-icon v-if="isEdit" style="align-self: flex-end;flex-shrink: 0;width: 16px;height: 16px;position: relative;bottom: 8px;color: var(--color-text-2);cursor: pointer;" class="item-icon" @click="handleDelete(index)" name="shanchu"></svg-icon>
        </div>
        <div v-if="isEdit">
          <el-button :icon="Plus" type="text" @click="handleAddInput">添加应用</el-button>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="drawer-footer">
        <el-button v-if="isEdit" @click="onCancel">取消</el-button>
        <el-button type="primary" @click="onConfirm">确认</el-button>
      </div>
    </template>
  </el-drawer>
  <el-dialog
    title="镜像应用示例"
    v-model="isExampleShow"
    width="1000px"
    @close="isExampleShow = false"
  >
    <div>编辑信息后在页面上的应用效果如图所示</div>
    <img style="width: 100%;" :src="$img('custom-images/example.png')" alt="">
  </el-dialog>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits, toRaw } from 'vue'
import { message } from '@/hooks/useUI'
import { Plus } from '@element-plus/icons-vue'
import { postaddImages } from 'services/custom-images'
import emitter from 'app/emitter'

const props = defineProps({
  visible: Boolean,
  initialData: Object,
  isEdit: Boolean
})
const isExampleShow = ref(false)
const handleAddInput = () => {
  form.value.software.push({ name: '', alias: '', major: '', build: '' })
  rules.software = generateSoftwareRules()
}
const handleDelete = index => {
  form.value.software.splice(index, 1)
}
const emit = defineEmits(['update:visible', 'submit'])

// 控制本地 visible
const localVisible = ref(props.visible)
watch(
  () => props.visible,
  (newVal) => {
    localVisible.value = newVal
  }
)

watch(
  () => localVisible.value,
  (newVal) => {
    emit('update:visible', newVal)
  },
  { immediate: true }
)

// 表单数据
const form = ref(JSON.parse(JSON.stringify(props.initialData)))
const formRef = ref(null)

const isFormChanged = () => JSON.stringify(toRaw(form.value)) !== JSON.stringify(props.initialData)
const generateSoftwareRules = () => {
  return form.value.software.map((item, index) => ({
    name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
    alias: [{ required: true, message: '请输入缩写', trigger: 'blur' }],
    major: [{ required: true, message: '请输入产品版本号', trigger: 'blur' }],
    build: [{ required: true, message: '请输入构建版本号', trigger: 'blur' }]
  }))
}
// 校验规则
const rules = {
  os_alias: [
    { required: true, message: '操作系统不能为空，请填写操作系统后再保存', trigger: 'blur' }
  ],
  software: generateSoftwareRules()
}

function removeEmptyStrings(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== '')
  )
}
// 确认
const onConfirm = () => {
  if (!props.isEdit) {
    localVisible.value = false
    return
  }
  if (!isFormChanged() && props.isEdit) {
    localVisible.value = false
    return
  }
  formRef.value.validate((valid) => {
    if (valid) {
      form.value = removeEmptyStrings(form.value)
      const software = form.value.software.map(item => {
        return {
          ...item,
          arch: item.arch || form.value.arch,
          lang: item.lang || form.value.lang
        }
      })
      const data = {
        software,
        os_alias: form.value.os_alias,
        image_id: form.value.id
      }
      // if (!data.desc) delete data.desc
      postaddImages(data).then(res => {
        const { code, message: errMessage } = res
        if (code) {
          message(errMessage, 'error')
        } else {
          message('操作成功')
          emitter.emit('imageInfo', res)
          localVisible.value = false
        }
      })
    }
  })
}

// 取消
const onCancel = () => {
  localVisible.value = false
}
</script>

<style lang="scss" scoped>
.drawer-footer {
  display: flex;
  justify-content: flex-end;
}
.error-text {
  color: red;
  font-size: 12px;
}
.custom-form {
  :deep(.el-form-item__label) {
    margin-right: 12px;
  }
  .custom-number-input {
    width: 180px;
    :deep(input) {
      padding: 0 !important;
    }
  }
}
</style>
