<template>
  <div class="images-no-auth">
    <div class="top">
      <p class="top-title">{{ $t('images.noAuth.title') }}</p>
      <p>{{ $t('images.desc1') }}</p>
      <p>{{ $t('images.desc2') }}</p>
    </div>
    <div class="content">
      <div class="carousel-wrapper">
        <el-carousel @change="change" :class="{rightDisabled:index === 3, leftDisabled: !index}" arrow="always" :loop="false" :autoplay="false" height="500px">
        <el-carousel-item v-for="item in 4" :key="item">
          <div>
            <img :src="$img(`custom-images/${item}.png`)" alt="">
            <p>{{$t('images.noAuth.carouselText' + item)}}</p>
          </div>
        </el-carousel-item>
        </el-carousel>
      </div>
      <el-button class="apply-btn" @click="$router.push('/help/consulting')" type="primary">{{ $t('images.noAuth.apply') }}</el-button>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
const index = ref(0)
function change(idx) {
  index.value = idx
}
</script>
<style lang="scss" scoped>
[data-theme=light]{
  .images-no-auth  {
    .top {
      background-image: url('~@/assets/img/images/no-auth-top-1-light.png');
    }
  }
}
.images-no-auth {
  width: 100%;
  .top {
    width: 100%;
    height: 200px;
    background: linear-gradient(0deg, #14151C 0%, rgba(16, 18, 27, 0) 31%);
    // background-color: #14151C;
    background-image: url('~@/assets/img/images/no-auth-top-1.png');
    background-repeat: no-repeat;
    background-size: auto 200px;
    background-position: right center;
    padding: 38px 48px;
    box-sizing: border-box;
    &-title {
      font-size: 20px !important;
      font-weight: 500;
      line-height: 32px;
      // color: #C3CCD9 !important;
      color: var(--color-text-2) !important;
      margin-bottom: 10px !important;
    }
    p {
      margin: 0;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 0px;
      color: var(--color-text-2);
    }
  }
  .content {
    background: var(--color-bg-1);
    margin: 0 24px 24px 24px;
    height: calc(100vh - 280px);
  }
  .carousel-wrapper {
    width: 1000px;
    margin: 0 auto;
  }
  .leftDisabled {
    :deep(.el-carousel__arrow--left) i {
      cursor: not-allowed;
      filter: grayscale(50%) opacity(0.6);
    }
  }
  .rightDisabled {
    :deep(.el-carousel__arrow--right) i {
      cursor: not-allowed;
      filter: grayscale(50%) opacity(0.6);
    }
  }
  :deep(.el-carousel) {
    .el-carousel__item div {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-size: 20px;
        color: var(--color-text-2);
      }
      img {
        width: 500px;
      }
    }
    .el-carousel__arrow {
      font-size: 50px;
      background-color: transparent;
      color: #767F8C;
    }
    .el-carousel__indicator.is-active button {
      background-color: #00ab7a;
    }
    .el-carousel__arrow {
      display: block !important;
    }
    // .el-carousel__arrow--right {
    //   right: 200px;
    // }
    // .el-carousel__arrow--left {
    //   left: 200px;
    // }
  }
  .apply-btn {
    margin: 30px auto;
    display: block;
  }
}
</style>
